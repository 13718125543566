jQuery(document).ready(function ($) {
  var $menu = $("#menu"),
    $menulink = $(".menu-link"),
    $menuTrigger = $(".menu-item-has-children > a:after");

  $menulink.click(function (e) {
    e.preventDefault();
    $menulink.toggleClass("active");
    $menu.toggleClass("active");
  });

  $menuTrigger.click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $this.toggleClass("active").next("ul").toggleClass("active");
  });
});

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

/* Author: Torsten Nienaber

*/

jQuery(document).ready(function ($) {
  $.featureList($("#tabs li a"), $("#output li"), {
    start_item: 0,
    transition_interval: 5000,
  });

  $(".mainContent").fitVids();

  $(".half").localScroll();
  $(".scroll").localScroll();
  // Navigation
  $("#menu-wrap").hide();
  $(".btn-close").hide();

  $(".btn-open").on("click", function () {
    $("#menu-wrap").slideToggle("fast");
    $(".btn-open").addClass("hide");
    $(".btn-close").addClass("show");
  });

  $(".btn-close").on("click", function () {
    $("#menu-wrap").slideToggle("fast");
    $(".btn-open").removeClass("hide");
    $(".btn-close").removeClass("show");
  });

  $(".menu-item-has-children > a").after(
    '<button class="dropdown-toggle" aria-expanded="false"><span class="screen-reader-text"></span></button>'
  );

  $(".dropdown-toggle").click(function (e) {
    var _this = $(this);
    e.preventDefault();
    _this.toggleClass("toggle-on");
    _this.next(".children, .sub-menu").toggleClass("toggled-on");
    _this.attr(
      "aria-expanded",
      _this.attr("aria-expanded") === "false" ? "true" : "false"
    );
    _this.html(
      _this.html() === screenReaderText.expand
        ? screenReaderText.collapse
        : screenReaderText.expand
    );
  });

  function onResizeARIA() {
    if (1022 > $window.width()) {
      button.attr("aria-expanded", "false");
      secondary.attr("aria-expanded", "false");
      button.attr("aria-controls", "secondary");
    } else {
      button.removeAttr("aria-expanded");
      secondary.removeAttr("aria-expanded");
      button.removeAttr("aria-controls");
    }
  }
});
